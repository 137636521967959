<script lang="ts" setup>
const userStore = useUserStore();
const { user } = storeToRefs(userStore);
const { logout: _logout } = useAuth();

async function logout() {
  _logout();
  await navigateTo("/account/login");
}
</script>
<template>
  <button v-if="user" class="font-medium text-yz-blue-600" @click="logout">
    {{ $t("user.settings.button.logout") }}
  </button>
</template>
